<template>
  <DialogBase
    :dialogWidth="900"
    :dialogModel="dialogModel"
    @closeDialog="(val) => closeDialog(val)"
    scrollable
  >
    <template v-slot:title>Application Source Management</template>

    <v-card elevation="0" class="ma-0 pa-0">
      <v-card-text class="ma-0 pa-0">
        <v-row class="my-6">
          <v-col cols="4">
            <v-card
              class="fill-height transparent-1"
              @click="showCreateUpdateApplicationSourceDialog()"
            >
              <v-card-title>
                <v-spacer></v-spacer>
                Add New Application Source
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text class="text-center">
                <v-icon color="primary" large> mdi-plus-circle-outline </v-icon>
              </v-card-text>
            </v-card>
          </v-col>
          <template v-for="(source, index) in applicationSources">
            <v-col cols="4" :key="index">
              <v-card
                class="fill-height transparent-1"
                @click="showCreateUpdateApplicationSourceDialog(source)"
              >
                <v-card-title>
                  <v-spacer></v-spacer>
                  {{ source.name }}
                  <v-spacer></v-spacer>
                </v-card-title>
                <template v-for="(agent, index) in source.agents">
                  <v-card-text
                    :key="index"
                    class="text-center my-0 py-1"
                    v-html="`${agent.user.name} <br />${agent.user.email}`"
                  >
                  </v-card-text>
                </template>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>
    <v-card elevation="0" class="ma-0 pa-0">
      <v-card-title class="pa-2 ma-0">
        <v-spacer></v-spacer>
        Jangl Lead Source Mappings
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="ma-0 pa-0">
        <v-row class="my-6">
          <v-col cols="4">
            <v-card
              class="fill-height transparent-1"
              @click="showCreateUpdateJanglLeadSourceMappingDialog()"
            >
              <v-card-title>
                <v-spacer></v-spacer>
                Add Lead Source Mapping
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text class="text-center">
                <v-icon color="primary" large> mdi-plus-circle-outline </v-icon>
              </v-card-text>
            </v-card>
          </v-col>
          <template v-for="(mapping, index) in jangleLeadSourceMappingList">
            <v-col cols="4" :key="index">
              <v-card
                class="fill-height transparent-1"
                @click="showCreateUpdateJanglLeadSourceMappingDialog(mapping)"
              >
                <v-card-title>
                  {{ mapping.source_name }}
                  <v-spacer></v-spacer>
                  <v-btn
                    @click.stop.prevent="deleteJanglLeadSourceMapping(mapping)"
                    color="error"
                    icon
                    small
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="text-center">
                  {{ mapping.identifier }}
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>
    <v-card elevation="0" class="ma-0 pa-0">
      <v-card-title class="pa-2 ma-0">
        <v-spacer></v-spacer>
        Application Source Zoho Module Mappings
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="ma-0 pa-0">
        <v-row class="my-6">
          <v-col cols="4">
            <v-card
              class="fill-height transparent-1"
              @click="showCreateUpdateApplicationSourceZohoCrmMappingDialog()"
            >
              <v-card-title>
                <v-spacer></v-spacer>
                Add Mapping
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text class="text-center">
                <v-icon color="primary" large> mdi-plus-circle-outline </v-icon>
              </v-card-text>
            </v-card>
          </v-col>
          <template
            v-for="(mapping, index) in applicationSourceZohoCrmMappingList"
          >
            <v-col cols="4" :key="index">
              <v-card
                class="fill-height transparent-1"
                @click="
                  showCreateUpdateApplicationSourceZohoCrmMappingDialog(mapping)
                "
              >
                <v-card-title>
                  {{ mapping.application_source_name }}
                  <v-spacer></v-spacer>
                  <v-btn
                    @click.stop.prevent="
                      deleteApplicationSourceZohoCrmMapping(mapping)
                    "
                    color="error"
                    icon
                    small
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="text-center">
                  {{ mapping.zoho_source_name }}
                  <br />
                  {{ mapping.zoho_modules }}
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>
    <v-card elevation="0" class="ma-0 pa-0">
      <v-card-title class="pa-2 ma-0">
        <v-spacer></v-spacer>
        Application Source YTel Mappings
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="ma-0 pa-0">
        <v-row class="my-6">
          <v-col cols="4">
            <v-card
              class="fill-height transparent-1"
              @click="showCreateUpdateApplicationSourceYTelMappingDialog()"
            >
              <v-card-title>
                <v-spacer></v-spacer>
                Add Mapping
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text class="text-center">
                <v-icon color="primary" large> mdi-plus-circle-outline </v-icon>
              </v-card-text>
            </v-card>
          </v-col>
          <template
            v-for="(mapping, index) in applicationSourceYTelMappingList"
          >
            <v-col cols="4" :key="index">
              <v-card
                class="fill-height transparent-1"
                @click="
                  showCreateUpdateApplicationSourceYTelMappingDialog(mapping)
                "
              >
                <v-card-title>
                  {{ mapping.application_source_name }}
                  <v-spacer></v-spacer>
                  <v-btn
                    @click.stop.prevent="
                      deleteApplicationSourceYTelMapping(mapping)
                    "
                    color="error"
                    icon
                    small
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="text-center">
                  List: {{ mapping.ytel_list_id }}
                  <br />
                  Campaign ID: {{ mapping.ytel_campaign_id || "N/A" }}
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>

    <template v-slot:footer>
      <v-btn color="accent" outlined block @click="closeDialog()">Close</v-btn>
    </template>
    <template v-if="createUpdateApplicationSourceDialog">
      <createUpdateApplicationSourceDialog
        :dialogModel="createUpdateApplicationSourceDialog"
        :selectedModel="selectedApplicationSource"
        @closeDialog="closeCreateUpdateApplicationSourceDialog()"
        @created="closeCreateUpdateApplicationSourceDialog()"
        @refresh="getApplicationSources()"
      />
    </template>
    <template v-if="createUpdateJanglLeadSourceMappingDialog">
      <createUpdateJanglLeadSourceMappingDialog
        :dialogModel="createUpdateJanglLeadSourceMappingDialog"
        :selectedModel="selectedJanglLeadSourceMapping"
        :applicationSources="applicationSources"
        @closeDialog="closeCreateUpdateJanglLeadSourceMappingDialog()"
        @created="closeCreateUpdateJanglLeadSourceMappingDialog()"
        @refresh="getJanglLeadSourceMappingList()"
      />
    </template>
    <template v-if="createUpdateApplicationSourceZohoCrmMappingDialog">
      <createUpdateApplicationSourceZohoCrmMappingDialog
        :dialogModel="createUpdateApplicationSourceZohoCrmMappingDialog"
        :selectedModel="selectedApplicationSourceZohoCrmMapping"
        :applicationSources="applicationSources"
        @closeDialog="closeCreateUpdateApplicationSourceZohoCrmMappingDialog()"
        @created="closeCreateUpdateApplicationSourceZohoCrmMappingDialog()"
        @refresh="getApplicationSourceZohoCrmMappingList()"
      />
    </template>
    <template v-if="createUpdateApplicationSourceYTelMappingDialog">
      <createUpdateApplicationSourceYTelMappingDialog
        :dialogModel="createUpdateApplicationSourceYTelMappingDialog"
        :selectedModel="selectedApplicationSourceYTelMapping"
        :applicationSources="applicationSources"
        @closeDialog="closeCreateUpdateApplicationSourceYTelMappingDialog()"
        @created="closeCreateUpdateApplicationSourceYTelMappingDialog()"
        @refresh="getApplicationSourceYTelMappingList()"
      />
    </template>
  </DialogBase>
</template>

<script>
import FormMaker from "@/components/FormMaker";
import DialogBase from "@/components/dialogs/Base";
import createUpdateApplicationSourceDialog from "@/components/dialogs/createUpdateApplicationSourceDialog";
import createUpdateJanglLeadSourceMappingDialog from "@/components/dialogs/createUpdateJanglLeadSourceMappingDialog";
import createUpdateApplicationSourceZohoCrmMappingDialog from "@/components/dialogs/createUpdateApplicationSourceZohoCrmMappingDialog";
import createUpdateApplicationSourceYTelMappingDialog from "@/components/dialogs/createUpdateApplicationSourceYTelMappingDialog";
export default {
  components: {
    DialogBase,
    FormMaker,
    createUpdateApplicationSourceDialog,
    createUpdateJanglLeadSourceMappingDialog,
    createUpdateApplicationSourceZohoCrmMappingDialog,
    createUpdateApplicationSourceYTelMappingDialog,
  },
  props: {
    dialogModel: { required: true, type: Boolean },
    selectedModel: { required: true, default: null },
  },
  data() {
    return {
      createUpdateApplicationSourceDialog: false,
      createUpdateJanglLeadSourceMappingDialog: false,
      createUpdateApplicationSourceZohoCrmMappingDialog: false,
      createUpdateApplicationSourceYTelMappingDialog: false,
      selectedApplicationSource: null,
      selectedJanglLeadSourceMapping: null,
      applicationSources: [],
      jangleLeadSourceMappingList: [],
      applicationSourceZohoCrmMappingList: [],
      applicationSourceYTelMappingList: [],
      selectedApplicationSourceYTelMapping: null,
    };
  },
  mounted() {
    this.getApplicationSources();
    this.getJanglLeadSourceMappingList();
    this.getApplicationSourceZohoCrmMappingList();
    this.getApplicationSourceYTelMappingList();
  },
  computed: {},
  methods: {
    closeDialog(val = false) {
      this.$emit("closeDialog", val);
    },
    showCreateUpdateApplicationSourceDialog(selectedApplicationSource = null) {
      this.selectedApplicationSource = selectedApplicationSource;
      this.createUpdateApplicationSourceDialog = true;
    },
    closeCreateUpdateApplicationSourceDialog() {
      this.selectedApplicationSource = null;
      this.createUpdateApplicationSourceDialog = false;
    },
    showCreateUpdateApplicationSourceZohoCrmMappingDialog(
      selectedApplicationSourceZohoCrmMapping = null
    ) {
      this.selectedApplicationSourceZohoCrmMapping =
        selectedApplicationSourceZohoCrmMapping;
      this.createUpdateApplicationSourceZohoCrmMappingDialog = true;
    },
    closeCreateUpdateApplicationSourceZohoCrmMappingDialog() {
      this.selectedApplicationSourceZohoCrmMapping = null;
      this.createUpdateApplicationSourceZohoCrmMappingDialog = false;
    },
    showCreateUpdateApplicationSourceYTelMappingDialog(
      selectedApplicationSourceYTelMapping = null
    ) {
      this.selectedApplicationSourceYTelMapping =
        selectedApplicationSourceYTelMapping;
      this.createUpdateApplicationSourceYTelMappingDialog = true;
    },
    closeCreateUpdateApplicationSourceYTelMappingDialog() {
      this.selectedApplicationSourceYTelMapping = null;
      this.createUpdateApplicationSourceYTelMappingDialog = false;
    },
    showCreateUpdateJanglLeadSourceMappingDialog(
      selectedJanglLeadSourceMapping = null
    ) {
      this.selectedJanglLeadSourceMapping = selectedJanglLeadSourceMapping;
      this.createUpdateJanglLeadSourceMappingDialog = true;
    },
    closeCreateUpdateJanglLeadSourceMappingDialog() {
      this.selectedJanglLeadSourceMapping = null;
      this.createUpdateJanglLeadSourceMappingDialog = false;
    },
    async getApplicationSources() {
      let data = {};
      return await this.post(
        this.baseUrl + `/agent-portal/get-application-source-list`,
        data,
        false
      ).then((data) => {
        if (data == "undefined") return;
        if (data.code == 200) {
          this.applicationSources = data?.data?.application_source_list;
          // console.log(JSON.stringify(this.applicationSources));
        }
      });
    },
    async getJanglLeadSourceMappingList() {
      let data = {};
      return await this.post(
        this.baseUrl + `/agent-portal/get-jangl-lead-source-mapping-list`,
        data,
        false
      ).then((data) => {
        if (data == "undefined") return;
        if (data.code == 200) {
          this.jangleLeadSourceMappingList =
            data?.data?.jangl_lead_source_mapping_list;
          // console.log(JSON.stringify(this.applicationSources));
        }
      });
    },
    async deleteJanglLeadSourceMapping(selectedJanglLeadSourceMapping) {
      if (!confirm("Are you sure you wanted to delete this Jangl lead source?"))
        return false;

      let data = {
        id: selectedJanglLeadSourceMapping?.id,
      };
      return await this.post(
        this.baseUrl + `/agent-portal/delete-jangl-lead-source-mapping`,
        data,
        true
      ).then((data) => {
        if (data == "undefined") return;
        if (data.code == 200) {
          this.getJanglLeadSourceMappingList();
        }
      });
    },
    async getApplicationSourceZohoCrmMappingList() {
      let data = {};
      return await this.post(
        this.baseUrl +
          `/agent-portal/get-application-source-zoho-crm-mapping-list`,
        data,
        false
      ).then((data) => {
        if (data == "undefined") return;
        if (data.code == 200) {
          this.applicationSourceZohoCrmMappingList =
            data?.data?.application_source_zoho_crm_mapping_list;
          // console.log(JSON.stringify(this.applicationSources));
        }
      });
    },
    async deleteApplicationSourceZohoCrmMapping(
      selectedJanglLeadSourceMapping
    ) {
      if (
        !confirm(
          "Are you sure you wanted to delete this Application source Zoho Crm mapping?"
        )
      )
        return false;

      let data = {
        id: selectedJanglLeadSourceMapping?.id,
      };
      return await this.post(
        this.baseUrl +
          `/agent-portal/delete-application-source-zoho-crm-mapping`,
        data,
        true
      ).then((data) => {
        if (data == "undefined") return;
        if (data.code == 200) {
          this.getApplicationSourceZohoCrmMappingList();
        }
      });
    },
    async getApplicationSourceYTelMappingList() {
      let data = {};
      return await this.post(
        this.baseUrl + `/agent-portal/get-application-source-ytel-mapping-list`,
        data,
        false
      ).then((data) => {
        if (data == "undefined") return;
        if (data.code == 200) {
          this.applicationSourceYTelMappingList =
            data?.data?.application_source_ytel_mapping_list;
          // console.log(JSON.stringify(this.applicationSources));
        }
      });
    },
    async deleteApplicationSourceYTelMapping(selectedJanglLeadSourceMapping) {
      if (
        !confirm(
          "Are you sure you wanted to delete this Application source YTel mapping?"
        )
      )
        return false;

      let data = {
        id: selectedJanglLeadSourceMapping?.id,
      };
      return await this.post(
        this.baseUrl + `/agent-portal/delete-application-source-ytel-mapping`,
        data,
        true
      ).then((data) => {
        if (data == "undefined") return;
        if (data.code == 200) {
          this.getApplicationSourceYTelMappingList();
        }
      });
    },
  },
};
</script>
