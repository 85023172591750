<template>
	<DialogBase
		:dialogWidth="700"
		:dialogModel="dialogModel"
		@closeDialog="val => closeDialog(val)"
		scrollable
	>
		<template v-slot:title>
			{{ selectedModel ? `Update` : `Create` }} Application Source Zoho Crm
			Mapping
		</template>

		<v-form id="form" @submit.prevent v-model="isValidForm" ref="from">
			<FormMaker :schema="form_structure" :model="form_data"></FormMaker>
		</v-form>

		<template v-slot:footer>
			<v-btn color="accent" outlined block @click="submit()">
				{{ selectedModel ? `Update` : `Create` }}
			</v-btn>
		</template>
	</DialogBase>
</template>

<script>
	import { required, minLen } from "@/plugins/validationRules.js";
	import FormMaker from "@/components/FormMaker";
	import DialogBase from "@/components/dialogs/Base";
	export default {
		components: { DialogBase, FormMaker },
		props: {
			dialogModel: { required: true, type: Boolean },
			selectedModel: { required: true, default: null },
			applicationSources: { required: true, default: [], type: Array }
		},
		data() {
			return {
				zohoCrmModuleList: [],
				isValidForm: false,
				form_data_default: {
					application_source_name: null,
					zoho_modules: null,
					zoho_source_name: "RocketFlood.com"
				},
				form_data: {}
			};
		},
		mounted() {
			this.getZohoCrmModuleList();
			if (this.selectedModel) {
				this.form_data = Object.assign(
					this.form_data_default,
					this.selectedModel
				);
			} else {
				this.form_data = this.form_data_default;
			}
		},
		watch: {
			selectedModel(newVal, oldVal) {
				// console.log(newVal);
				if (newVal) this.form_data = Object.assign({}, this.selectedModel);
			}
		},
		computed: {
			form_structure() {
				let form = {
					application_source_name: {
						type: "select",
						name: "application_source_name",
						label: "Source Name",
						...this.$helpers.formFieldsBasicLayout,
						items: this.applicationSources,
						itemText: "name",
						itemValue: "name",
						rules: [required()]
					},
					zoho_modules: {
						type: "select",
						name: "zoho_modules",
						label: `Zoho Module`,
						...this.$helpers.formFieldsBasicLayout,
						items: this.zohoCrmModuleList,
						multiple: true,
						rules: [required()]
					},
					zoho_source_name: {
						type: "text",
						name: "zoho_source_name",
						label: `Zoho Source Name`,
						...this.$helpers.formFieldsBasicLayout,
						rules: [required()]
					}
				};
				return form;
			}
		},
		methods: {
			closeDialog(val = false) {
				this.$emit("closeDialog", val);
			},
			refresh(val = false) {
				this.$emit("refresh");
			},
			async getZohoCrmModuleList() {
				let data = {};
				return await this.post(
					this.baseUrl + `/agent-portal/get-zoho-crm-module-list`,
					data,
					false
				).then(data => {
					if (data == "undefined") return;
					if (data.code == 200) {
						this.zohoCrmModuleList = data?.data?.zoho_crm_module_list;
						// console.log(JSON.stringify(this.applicationSources));
					}
				});
			},
			async submit() {
				this.$refs.from.validate();
				if (!this.isValidForm) return;

				let data = {
					...this.form_data
				};

				if (this.selectedModel)
					return await this.post(
						`${this.baseUrl}/agent-portal/create-update-application-source-zoho-crm-mapping`,
						data,
						true
					).then(data => {
						if (data == "undefined") return;
						this.showMessageFromResponseDta(data);

						if (data.code == 200) {
							this.refresh();
							this.closeDialog();
						}
					});

				return await this.post(
					`${this.baseUrl}/agent-portal/create-update-application-source-zoho-crm-mapping`,
					data,
					true
				).then(data => {
					if (data == "undefined") return;
					this.showMessageFromResponseDta(data);

					if (data.code == 200) {
						this.refresh();
						this.closeDialog();
					}
				});
			}
		}
	};
</script>
